import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { media } from "../libs/media";
import Wrapper from "./sectionWrapper";
import { MobileNavbar } from "./MobileNavbar";
import { LinkGroup } from "./LinkGroup";
import { LinkIcon } from "./LinkIcon";
import { Icon } from "@iconify/react";
import { useLangContext } from "./translateContext";
import Messages from "./translations/Navbar.json";
import Dropdown from "./dropdown";
import DropdownFlag from "./dropdownflag";
const { online, offline, digitalFinance, shareLink, credit, shoppi } = media.Icons[1];


export default function Navbar() {
  const [hide, setHide] = useState(false);
  const { pathname } = useLocation();
  const [dark, setDark] = useState(false);
  const [background, setbackground] = useState(false);

  const { Lang } = useLangContext();
  const Infos = Messages[Lang];
  useEffect(() => {
    if (/\/docs/.test(pathname)) {
      setHide(true);
    } else {
      setHide(false);
    }
    if (pathname !== "/") {
      setDark(true);
    } else {
      setDark(false);
    }
    function handleScroll(e) {
      if (this.scrollY) {
        setbackground(true);
        setDark(false);
      } else {
        setbackground(false);
        if (pathname !== "/") {
          setDark(true);
        } else {
          setDark(false);
        }
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  return (
    <Wrapper
      childrenClassName={`flex justify-between h-8 md:h-10  ${
        dark ? "text-qosdark" : "text-qosdark"
      } text-opacity-70 items-center justify-between`}
      className={`z-50 top-0 py-5 ${background && "bg-qosgray shadow-lg"} ${
        hide && "hidden"
      } rounded-none py-4 justify-between fixed w-full`}
    >
      <Link to="/">
        <div className="h-full w-16 md:w-20 lg:w-20">
          <img
            src={media.Logos.qos.qosLogoBlack}
            className="w-16 md:w-20 lg:w-20"
            alt="QOS_LOGO"
          />
        </div>
      </Link>
      <nav id="navbarLink" className="pl-2 max-h-full w-8/12 lg:w-10/12">
        <ul className=" hidden w-full text-dlg items-center justify-left text-opacity-80 font-medium lg:flex">
          <li className="relative group block mx-1.5 lg:mx-0 xl:mx-5 ">
            <Link id="product" to="#" className="text-qosblackText font-bold md:text-lg hover:text-qosdark">
              {Infos.Links.products.name}
              <span className="fi fi-rr-angle-small-down align-bottom inline-block text-msm ml-1"></span>
             
            </Link>
            <nav
              id="products"
              className="text-qosblackText bg-qosgray w-82 z-0 -ml-10 scale-y-0 origin-bottom -mt-24 group-hover:mt-0 opacity-0 group-hover:opacity-100 group-hover:scale-y-100 transform ease-out  duration-200 rounded-md  absolute p-5 pt-5  text-qosdark"
            >
              <LinkGroup>
                <LinkIcon
                  icon={online}
                  name={Infos.Links.products.items[0].title}
                  description={Infos.Links.products.items[0].description}
                  link="/online-payment"
                />
                <LinkIcon
                  icon={shareLink}
                  name={Infos.Links.products.items[2].title}
                  description={Infos.Links.products.items[2].description}
                  link="/shareLink-payment"
                />
                <LinkIcon
                  icon={credit}
                  name={Infos.Links.products.items[4].title}
                  description={Infos.Links.products.items[4].description}
                  link="/checkout"
                />

                <LinkIcon
                  icon={offline}
                  name={Infos.Links.products.items[1].title}
                  description={Infos.Links.products.items[1].description}
                  link="/offline-payment"
                />
                 
                 <LinkIcon
                  icon={shoppi}
                  name={Infos.Links.products.items[5].title}
                  description={Infos.Links.products.items[5].description}
                  link="/store"
                />
                
               
                 {/* <LinkIcon
                  icon={digitalFinance}
                  name={Infos.Links.products.items[3].title}
                  description={Infos.Links.products.items[3].description}
                  link="/digital-finance"
                /> */}
                
              
              </LinkGroup>
            </nav>
          </li>
          {/* <li className="block mx-1.5 md:text-lg lg:mx-2.5 xl:mx-5">
            <Link
              id="developer"
              // target="_blank"
              className="font-bold text-qosblackText hover:text-qosdark"
              to="/docs"
            >
              {Infos.Links.solution}
            </Link>
          </li> */}
          <li className="block mx-1.5 lg:mx-2.5 xl:mx-5">
            <Link
              id="developer"
              target="_blank"
              className="font-bold text-qosblackText hover:text-qosdark"
              // to="/docs"
              to={{pathname:"https://docs.qosic.com/"}}
              
            >
              {Infos.Links.developers}
            </Link>
          </li>
          <li className="block mx-1.5 lg:mx-2.5 xl:mx-5">
            <Link className="font-bold text-qosblackText hover:text-qosdark" to="/pricing">
              {Infos.Links.price}
            </Link>
          </li>
          {/* <li className="block mx-1.5 lg:mx-2.5 xl:mx-5">
            <Link
              id="developer"
              className="font-bold hover:text-qosdark"
              to="/blog"
            >
              Blog
            </Link>
          </li> */}
          <li className="relative group block mx-1.5 lg:mx-0 xl:mx-5 ">
            <Link id="aboutUs" to="#" className="font-bold text-qosblackText hover:text-qosdark">
              {Infos.Links.company.name}
            </Link>
            <nav
              id="aboutUs"
              // bg-qosgray
              className="text-qosblackText bg-qosgray w-82 z-0 -ml-10 scale-y-0 origin-bottom -mt-24 group-hover:mt-0 opacity-0 group-hover:opacity-100 group-hover:scale-y-100 transform ease-out  duration-200 rounded-md  absolute p-5 pt-5  text-qosdark"
            >
              <LinkGroup>
                <LinkIcon
                  icon={online}
                  name={Infos.Links.company.items[0]}
                  description={Infos.Links.company.companyDescription}
                  link="/company"
                />
                <LinkIcon
                  icon={shareLink}
                  name={Infos.Links.company.items[1]}
                  description={Infos.Links.company.contacUdescription}
                  link="/contact-us"
                />
          
            
                
              
              </LinkGroup>
            </nav>
          </li>
          {/* <li className="block mx-1.5 lg:mx-2.5 xl:mx-5">
            <Link className="font-bold hover:text-qosdark" to="/contact-us">
              {Infos.Links.company.items[1]}
            </Link>
          </li> */}
        </ul>
      </nav>
      {/* <div className="flex">
      <li className="inline  hidden  lg:flex lg:mx-2.5 xl:mx-5">
            <Link
              id="contact"
              // target="_blank"
              className="font-bold hover:text-qosdark"
              to="/contact-us"
            >
              {Infos.Links.contact}
            </Link>
          </li>
          <span className="fi fi-rr-angle-small-right mt-1 font-bold self-end text-mmd"></span>

      </div> */}
      <div className="w-auto hidden  lg:flex ">
  
          <Link
            to="/contact-us"
            className="rounded-full relative px-1 ml-4  inline-flex items-center  backdrop-blur-3xl text-opacity-70"
          >
            <p className=" flex whitespace-nowrap font-bold hover:text-qosdark">
            {Infos.Links.contact} {Infos.Links.sales}
            </p>
            {/* <span className="fi fi-rr-angle-small-right mt-1 ml-2 font-bold self-end text-mmd"></span> */}
            <Icon
              icon="ion:chevron-forward"
              height={18}
              style={{ color: "black",paddingLeft:3, fontSize: "20px" }}
            />
          </Link>
        </div>
      
      
      <div className="hidden  lg:flex  justify-end w-1/12 xl:w-2/12">
        
        <a
          href="https://dashboard.qosic.com/"
          className={`p-2 px-8 font-bold inline-block rounded-lg  btn-sm max-w-max text-qosorange m-2 border  hover:text-opacity-50`}
        >
          {Infos.Links.logInButton}
        </a>
      </div>
      {/* <div className="pl-1 relative  w-50 hidden  lg:flex ">
      <DropdownFlag/>
      </div> */}
      <div className="h-10 relative">
        <Dropdown Short={true} />
      </div>
      <MobileNavbar />
    </Wrapper>
  );
}
